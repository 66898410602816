import { useRef, useState, useEffect } from "react";
import styles from "./Images.module.css";

import { ImageRequest, imageApi } from "../../api";
import { Image, ImageError, ImageLoading } from "../../components/ImageAnswer";
import ImageContainerLink from "../../components/ImageAnswer/ImageContainerLink";
import { QuestionInput } from "../../components/QuestionInput";

import { Spinner } from "@fluentui/react";

import { SettingsButton } from "../../components/SettingsButton";
// import { InputMic } from "../../components/InputMic/InputMic";

const Images = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);

    const lastQuestionRef = useRef<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [image, setImage] = useState<String>();

    const GetImageUrl = (message: string) => {
        const api_base = "https://estudosopenai-eastus.openai.azure.com";
        const api_key = "85e3e88e011e4957b6e21402dba968ba";
        const api_version = "2023-06-01-preview";
        const url = `${api_base}/openai/images/generations:submit?api-version=${api_version}`;
        const headers = { "api-key": api_key, "Content-Type": "application/json" };
        const body = {
            prompt: `${message}`,
            size: "1024x1024",
            n: 1
        };

        fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(data => {
                const idImage = data["id"];
                const urlImage = `${api_base}/openai/operations/images/${idImage}?api-version=2023-06-01-preview`;
                let status = "";

                const checkStatus = () => {
                    fetch(urlImage, {
                        headers: headers
                    })
                        .then(response => response.json())
                        .then(data => {
                            status = data.status;
                            if (status !== "succeeded") {
                                setTimeout(checkStatus, 1000); // Verifica novamente em 1 segundo
                            } else {
                                const image_url = data.result.data[0].url;
                                setImage(image_url);
                                console.log(image_url);
                            }
                        });
                };

                setTimeout(checkStatus, 1000); // Primeira verificação em 1 segundo
            })
            .catch(error => console.log(error));
    };

    return (
        <div className={styles.oneshotContainer}>
            <div className={styles.titleContainer}>
                <p className={styles.titleOne}>Crie</p>
                <p className={styles.titleTwo}>uma Imagem</p>
            </div>

            <div className={styles.oneshotQuestionInput}>
                <QuestionInput placeholder="Exemplo: Criar a imagem de um cachorro no parque" disabled={isLoading} onSend={question => GetImageUrl(question)} />
            </div>

            <div className={styles.oneshotBottomSection}>
                {isLoading && <Spinner label="Gerando Resposta" />}
                {!isLoading && image && !error && (
                    <div className={styles.oneshotAnswerContainer}>
                        <ImageContainerLink url={`${image}`} />
                    </div>
                )}
                {error ? (
                    <div className={styles.oneshotAnswerContainer}>
                        <ImageError error={error.toString()} onRetry={() => GetImageUrl(lastQuestionRef.current)} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Images;
