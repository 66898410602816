import { AskRequest, AskResponse, ChatRequest, ImageRequest, ImageResponse } from "./models";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function imageApi(options: ImageRequest): Promise<ImageResponse> {
    const api_base = "https://estudosopenai-eastus.openai.azure.com";
    const api_key = "85e3e88e011e4957b6e21402dba968ba";
    const api_version = "2023-06-01-preview";
    const url = `${api_base}/openai/images/generations:submit?api-version=${api_version}`;
    const headers = { "api-key": api_key, "Content-Type": "application/json" };
    const body = {
        prompt: options.question, //question?
        size: "1024x1024",
        n: 1
    };
    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    });

    const data = await response.json();

    const idImage = data["id"];
    const urlImage = `${api_base}/openai/operations/images/${idImage}?api-version=2023-06-01-preview`;
    let status = "";

    const checkStatus = () => {
        fetch(urlImage, {
            headers: headers
        })
            .then(response => response.json())
            .then(data => {
                status = data.status;
                if (status !== "succeeded") {
                    setTimeout(checkStatus, 1000); // Verifica novamente em 1 segundo
                } else {
                    const image_url = data.result.data[0].url;
                    console.log(image_url);
                }
            });
    };

    setTimeout(checkStatus, 1000); // Primeira verificação em 1 segundo

    return data;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}
