import React from "react";

import externallinkicon from "../../assets/externallinkicon.svg";

import styles from "./Image.module.css";

interface Props {
    url: string;
}

const ImageContainerLink = ({ url }: Props) => {
    return (
        <div>
            <a className={styles.linkDecoration} href={url} target="_blank">
                <div className={styles.imageContainerLink}>
                    <img src={externallinkicon} />

                    <p>clique aqui e abra sua imagem</p>
                </div>
            </a>
        </div>
    );
};

export default ImageContainerLink;
