import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Image.module.css";

interface Props {
    error: string;
    onRetry: () => void;
}

export const ImageError = ({ error, onRetry }: Props) => {
    return (
        <Stack className={styles.imageContainer} verticalAlign="space-between">
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item>
                <p className={styles.imageText}>{error}</p>
            </Stack.Item>

            <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
        </Stack>
    );
};
